import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { PageContext } from '../../Context/PageContext'
import { Input, FormGroup, Label } from 'reactstrap'
import { OptionManager } from './components/OptionManager'

const Question = withNamespaces()(({ t, subQuestion = false, className, ...elseProps }) => {
    const {
        setElementField,
        setElementStateField,
        deleteElement,
        setQuestionOptionChildStateField,
        setQuestionOptionChildField,
        deleteQuestionOptionChild,
        elementsRefs
    } = React.useContext(PageContext)

    const saveTitle = (newValue) => {
        if (!subQuestion) {
            setElementField(elseProps.id, 'title', newValue)
            setElementStateField(elseProps.id, 'editTitle', false)
        } else {
            setQuestionOptionChildField(
                elseProps.parentQuestionId,
                elseProps.optionIndex,
                elseProps.childIndex,
                'title',
                newValue
            )
            setQuestionOptionChildStateField(
                elseProps.parentQuestionId,
                elseProps.optionIndex,
                elseProps.childIndex,
                'editTitle',
                false
            )
        }
    }

    const makeTitleEditable = () => {
        if (!subQuestion) {
            setElementStateField(elseProps.id, 'editTitle', true)
        } else {
            setQuestionOptionChildStateField(
                elseProps.parentQuestionId,
                elseProps.optionIndex,
                elseProps.childIndex,
                'editTitle',
                true
            )
        }
    }

    const handleDeleteQuestion = () => {
        if (!subQuestion) {
            deleteElement(elseProps.id)
        } else {
            deleteQuestionOptionChild(
                elseProps.parentQuestionId,
                elseProps.optionIndex,
                elseProps.childIndex
            )
        }
    }

    const renderQuestionLiveViewInput = () => {
        switch (elseProps.questionType) {
            case 'date':
            case 'text':
            case 'textarea':
            case 'file_upload':
                return (
                    <Input
                        placeholder={t(elseProps.placeholder)}
                        disabled
                        type={elseProps.questionType}
                    />
                )

            case 'switch':
                return (
                    <div className='form-check form-switch'>
                        <Input
                            placeholder={t(elseProps.placeholder)}
                            className='form-check-input'
                            type='switch'
                            role='switch'
                        />
                    </div>
                )

            case 'radiobox':
                return (
                    <FormGroup check>
                        <OptionManager
                            subQuestion={subQuestion}
                            question={elseProps}
                        />
                    </FormGroup>
                )

            case 'checkbox':
                return (
                    <FormGroup check>
                        <OptionManager
                            subQuestion={subQuestion}
                            question={elseProps}
                        />
                    </FormGroup>
                )

            case 'select':
                return (
                    <FormGroup>
                        <Input disabled type='select'>
                            <option>{t(elseProps.placeholder)}</option>
                        </Input>
                        <OptionManager
                            subQuestion={subQuestion}
                            question={elseProps}
                        />
                    </FormGroup>
                )
            case 'legal_entity':
                return (
                    <FormGroup>
                        <Input disabled type='select'>
                            <option>{t(elseProps.placeholder)}</option>
                        </Input>
                    </FormGroup>
                )

            default:
                return null
        }
    }

    const classNames = subQuestion ? 'sub-question' : 'question'

    return (
        <div
            className={`smartintegrity__questionnaire__designer-question-item ${classNames} element ${className}`}
            ref={(ref) =>
                elementsRefs?.current &&
                elementsRefs.current.has(elseProps.id) &&
                elementsRefs.current.set(elseProps.id, ref)
            }
        >
            <span
                className={`smartintegrity__questionnaire__designer-drag-btn ${classNames} element ${className}`}
            >
                <i className='ri-drag-move-2-fill' />
            </span>
            <div className='smartintegrity__questionnaire__designer-question-title'>
                {elseProps?.state?.editTitle ? (
                    <Input
                        placeholder={t('Question Title')}
                        className='w-auto'
                        type='text'
                        defaultValue={elseProps.title}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                saveTitle(e.target.value)
                            }
                        }}
                        onBlur={(e) => saveTitle(e.target.value)}
                    />
                ) : (
                    <h5 onClick={makeTitleEditable}>{elseProps.title}</h5>
                )}
            </div>

            <div className='smartintegrity__questionnaire__designer-question-body'>
                {renderQuestionLiveViewInput()}
            </div>

            <div className='smartintegrity__questionnaire__designer-question-actions'>
                <div className='form-check'>
                    <Input
                        id={`question-${elseProps.id}-mandatory-input`}
                        type='checkbox'
                        value={elseProps.isMandatory}
                        onChange={(e) => {
                            if (!subQuestion) {
                                setElementField(
                                    elseProps.id,
                                    'isMandatory',
                                    e.target.value === 'true'
                                )
                            } else {
                                setQuestionOptionChildField(
                                    elseProps.parentQuestionId,
                                    elseProps.optionIndex,
                                    elseProps.childIndex,
                                    'isMandatory',
                                    e.target.value === 'true'
                                )
                            }
                        }}
                    />
                    <Label for={`question-${elseProps.id}-mandatory-input`}>
                        {t('Mandatory')}
                    </Label>
                </div>
                <button className='text-danger' onClick={handleDeleteQuestion}>
                    <i className='ri-delete-bin-line me-2' />
                    {t('Delete')}
                </button>
            </div>
        </div>
    )
})

export { Question }
