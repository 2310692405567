import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { withNamespaces } from 'react-i18next'
import { Row, Col, Button } from 'reactstrap'
import { ReactStrapQuestion } from '../../components/Question'

export const SimpleQuestionnaire = withNamespaces()(({
    questions,
    onReadyToSubmit,
    settings,
    className,
    t
}) => {
    const methods = useForm()

    const onSubmit = (data) => onReadyToSubmit && onReadyToSubmit(data)

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                {questions.map((question, index) => {
                    return (
                        <ReactStrapQuestion
                            key={index}
                            question={question}
                            settings={settings}
                        />
                    )
                })}

                <Row>
                    <Col sm='12'>
                        {!!settings?.buttons.submit?.enable && (
                            <Button
                                type='submit'
                                color='primary'
                                className={`${settings?.buttons?.submit?.customClass}`}
                            >
                                {t(
                                    settings?.buttons?.submit?.title || 'Submit'
                                )}
                            </Button>
                        )}
                    </Col>
                </Row>
            </form>
        </FormProvider>
    )
})
