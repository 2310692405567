import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { Controller } from 'react-hook-form'

const TextArea = withNamespaces()(React.memo(
    ({ name, label, validation, onAnswerStatusChanged,t, ...rest }) => {
        const {
            control,
            formState: { errors }
        } = rest

        control.register(name, {
            required: true
        })

        return (
            <FormGroup>
                {label && <Label for={name}>{t(label)}</Label>}
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            type='textarea'
                            onBlur={(e) => {
                                onAnswerStatusChanged &&
                                    onAnswerStatusChanged(name, e.target.value)
                                field.onBlur(e)
                            }}
                            id={name}
                        />
                    )}
                />
                {errors && errors[name] && (
                    <FormFeedback className='d-block' valid={false}>
                        {t(`This field cannot be blank`)}
                    </FormFeedback>
                )}
            </FormGroup>
        )
    }
))

export { TextArea }
