const renderQuestionAnswerByType = (questionAnswers, question, allValues) => {
    let result = []

    switch (question.type) {
        case 'textarea':
        case 'text':
        case 'date':
            result = [
                {
                    title: questionAnswers
                }
            ]
            break
        case 'checkbox':
        case 'category':
            questionAnswers.forEach((eachCheckedAnswer) => {
                const rawValue = question.options.find(
                    (option) =>
                        (option?.id || '').toString() ===
                        (eachCheckedAnswer || '').toString()
                )
                if (rawValue) {
                    result.push({
                        title: rawValue.title,
                        value: rawValue.id
                    })
                }
            })
            break

        case 'country_list':
        case 'select':
            result = [
                {
                    title: questionAnswers.label,
                    value: questionAnswers.value
                }
            ]
            break

        case 'file_upload':
            {
                const uploadedFiles =
                    questionAnswers && Array.isArray(questionAnswers)
                        ? questionAnswers
                        : []

                result = uploadedFiles.map((uploadedFile) => {
                    return {
                        title: uploadedFile.id,
                        name: uploadedFile.name
                    }
                })
            }
            break
        case 'radiobox':
            {
                const rawOption = question.options.find(
                    (option) =>
                        (option?.id || '').toString() ===
                        (questionAnswers || '').toString()
                )

                if (rawOption) {
                    switch (rawOption.type) {
                        case 'text':
                            result = [
                                {
                                    title: allValues[`${rawOption.id}-text`],
                                    value: rawOption.id
                                }
                            ]
                            break
                        case 'date':
                            result = [
                                {
                                    title: allValues[`${rawOption.id}-date`],
                                    value: rawOption.id
                                }
                            ]
                            break

                        default: {
                            result = [
                                {
                                    title: rawOption.title,
                                    value: rawOption.id
                                }
                            ]

                            const optionChildren = rawOption?.children || []

                            if (optionChildren.length > 0) {
                                const childrenAnswers = []

                                optionChildren.forEach((childQuestion) => {
                                    if (childQuestion.type === 'file_upload') {
                                        childrenAnswers.push({
                                            id: childQuestion.id,
                                            title: allValues[
                                                `${question.id}-${childQuestion.id}`
                                            ]?.id,
                                            type: 'file_upload',
                                            name: allValues[
                                                `${question.id}-${childQuestion.id}`
                                            ]?.name
                                        })
                                    } else if (
                                        childQuestion.type === 'radiobox'
                                    ) {
                                        childrenAnswers.push({
                                            id: childQuestion.id,
                                            title:
                                                allValues[
                                                    `${question.id}-${childQuestion.id}`
                                                ] === '1'
                                                    ? 'Yes'
                                                    : 'No',
                                            type: 'radiobox',
                                            value: allValues[
                                                `${question.id}-${childQuestion.id}`
                                            ]
                                        })
                                    } else {
                                        childrenAnswers.push({
                                            id: childQuestion.id,
                                            title: allValues[
                                                `${question.id}-${childQuestion.id}`
                                            ],
                                            type: childQuestion.type
                                        })
                                    }
                                })

                                result[0].children = childrenAnswers
                            }
                        }
                    }
                }
            }

            break
    }

    return result
}

const renderAnswer = (values, question) => {
    const itemResult = {
        question: question.id,
        answer: []
    }
    const questionAnswers = values[question.id]

    if (questionAnswers) {
        itemResult.answer = renderQuestionAnswerByType(
            questionAnswers,
            question,
            values
        )
    }

    return itemResult
}

const makeQuestionsAnswersReadyToSend = ({
    multiTab,
    questions,
    values,
    touchedFields
}) => {
    const result = []

    if (multiTab) {
        if (touchedFields) {
            const findQuestionById = (id) => {
                for (let index = 0; index < questions.length; index++) {
                    const section = questions[index]

                    for (let j = 0; j < section.questions.length; j++) {
                        const question = section.questions[j]

                        if (question.id.toString() === id) {
                            return question
                        }
                    }
                }
                return null
            }

            for (const touchedFieldKey in touchedFields) {
                const splitedFieldKey = touchedFieldKey.split('-')
                if (splitedFieldKey.length === 1) {
                    const question = findQuestionById(splitedFieldKey[0])
                    result.push(renderAnswer(values, question))
                }
            }
        } else {
            for (let index = 0; index < questions.length; index++) {
                const section = questions[index]

                section.questions.forEach((question) => {
                    result.push(renderAnswer(values, question))
                })
            }
        }
    }
    return result
}

const makeQuestionsReadyForQuestionnaire = (questions, multiTab = false) => {
    const result = []

    if (multiTab) {
        const sectionsNames = {}
        let sectionIndex = 0

        questions.forEach((question) => {
            if (!sectionsNames[question.section]) {
                sectionsNames[question.section] = sectionIndex++

                result[sectionsNames[question.section]] = {
                    title: question.section,
                    className: '',
                    questions: []
                }
            }

            result[sectionsNames[question.section]].questions.push(question)
        })
    }

    return result
}

const makePreviousAnswersReadyForQuestionnaire = (questions, answers) => {
    const result = {}

    questions.map((rawQuestion) => {
        const questionPreFilledAnswers = answers.find(
            (ans) => ans.id === rawQuestion.id
        )?.answers

        if (questionPreFilledAnswers) {
            const keyForQuestionAnswer = `${rawQuestion.id}`

            switch (rawQuestion.type) {
                case 'textarea':
                case 'text':
                case 'date':
                    result[keyForQuestionAnswer] =
                        questionPreFilledAnswers[0]?.title ||
                        rawQuestion?.system_data ||
                        ''
                    break

                case 'checkbox':
                case 'category':
                    result[keyForQuestionAnswer] = questionPreFilledAnswers.map(
                        (checkValue) => {
                            return checkValue.value
                        }
                    )
                    break

                case 'country_list':
                case 'select':
                    result[keyForQuestionAnswer] = questionPreFilledAnswers.map(
                        (eachValue) => {
                            return {
                                value: eachValue.value,
                                label: eachValue.title
                            }
                        }
                    )
                    break

                case 'file_upload':
                    break

                case 'radiobox':
                    result[keyForQuestionAnswer] =
                        questionPreFilledAnswers[0]?.value
                    break
                default:
                    return null
            }
        }
    })

    return result
}

const guessQuestionAnsweredByAnswerType = (answer) => {
    if (typeof answer === 'string' && answer !== '') {
        return true
    } else if (Array.isArray(answer) && answer.length > 0) {
        return true
    } else if (typeof answer === 'object' && Object.keys(answer).length > 0) {
        return true
    }

    return false
}

export {
    makeQuestionsAnswersReadyToSend,
    makeQuestionsReadyForQuestionnaire,
    makePreviousAnswersReadyForQuestionnaire,
    renderQuestionAnswerByType,
    guessQuestionAnsweredByAnswerType
}
