import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { FormGroup, Label, FormFeedback, Row, Col } from 'reactstrap'

const CheckBox = withNamespaces()(({
    name,
    label,
    validation,
    options,
    onAnswerStatusChanged,
    t,
    ...rest
}) => {
    const {
        register,
        formState: { errors, defaultValues }
    } = rest

    const handleInputOnChange = () => {
        onAnswerStatusChanged && onAnswerStatusChanged(name, rest.getValues(name))
    }

    const { onChange, ...registerRest } = register(name, {
        required: !!validation?.required
    })

    return (
        <FormGroup>
            {label && <Label for={name}>{t(label)}</Label>}
            <Row>
                <Col sm='12'>
                    <Row>
                        {options.map((option, index) => {
                            return (
                                <Col key={index} sm='12' md='4' lg='3'>
                                    <Label>
                                        <input
                                            type='checkbox'
                                            value={option?.id}
                                            defaultChecked={(defaultValues[name] || []).includes(option?.id)}
                                            className='form-check-input'
                                            {...registerRest}
                                            onChange={(e) => {
                                                onChange(e)
                                                handleInputOnChange(e)
                                            }}
                                        />{' '}
                                        {t(option?.title)}
                                    </Label>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
                <Col sm='12'>
                    {errors && errors[name] && (
                        <FormFeedback className='d-block' valid={false}>
                            {t(`This field cannot be blank`)}
                        </FormFeedback>
                    )}
                </Col>
            </Row>
        </FormGroup>
    )
})

export { CheckBox }
