import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import ReactSelect from 'react-select'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { Controller } from 'react-hook-form'

const Select = withNamespaces()(({
    name,
    label,
    validation,
    options,
    onAnswerStatusChanged,
    t,
    ...rest
}) => {
    const {
        control,
        formState: { errors }
    } = rest

    const selectOptions = options.map((option) => {
        return {
            value: option.id,
            label: t(option.title)
        }
    })

    control.register(name, {
        required: !!validation?.required
    })

    return (
        <FormGroup>
            {label && <Label for={name}>{t(label)}</Label>}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <ReactSelect
                        {...field}
                        isClearable={false}
                        options={selectOptions}
                        onChange={(e) => {
                            onAnswerStatusChanged && onAnswerStatusChanged(name, e)

                            field.onChange(e)
                        }}
                    />
                )}
            />
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
        </FormGroup>
    )
})

export { Select }
