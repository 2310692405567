import './styles.module.css'
import { SimpleQuestionnaire, MultiTabQuestionnaire } from './Questionnaire'

import { QuestionnaireDesigner } from './QuestionnaireDesigner'

import {
    makeQuestionsReadyForQuestionnaire,
    makePreviousAnswersReadyForQuestionnaire
} from './helpers/questionnaire'

const helpers = {
    makeQuestionsReadyForQuestionnaire,
    makePreviousAnswersReadyForQuestionnaire
}

export { SimpleQuestionnaire, MultiTabQuestionnaire, helpers }

export { QuestionnaireDesigner }
