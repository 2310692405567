import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { withNamespaces } from 'react-i18next'
import { Row, Col, Button } from 'reactstrap'
import { ReactStrapQuestion } from '../../components/Question'
import Accordion from 'react-bootstrap/Accordion'
import {
    makeQuestionsAnswersReadyToSend,
    renderQuestionAnswerByType,
    guessQuestionAnsweredByAnswerType
} from '../../helpers/questionnaire'

export const MultiTabQuestionnaire = withNamespaces()(({
    questions,
    defaultValues = {},
    onReadyToSubmit,
    settings,
    className,
    onChange,
    t
}) => {
    const methods = useForm({
        defaultValues: defaultValues
    })
    const [isMounted, setIsMounted] = React.useState(false)
    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    const [tabsAnsweredQuestions, setTabsAnsweredQuestions] =
        React.useState([])

    const onSubmit = (data) => {
        onReadyToSubmit &&
            onReadyToSubmit(
                makeQuestionsAnswersReadyToSend({
                    multiTab: true,
                    questions: questions,
                    values: data
                })
            )
    }

    const calculateQuestionnaireStatus = (tabIndex) => {
        const checkQuestionIsForTab = (tabIndex, questionId) => {
            const parentQuestionId = parseInt(
                questionId.toString().split('-')[0]
            )

            return !!questions[tabIndex].questions.find((q) => {
                return parseInt(q.id) === parentQuestionId
            })
        }

        let questionnaireCompleted = true
        let tabTotalRequiredQuestions = 0
        let tabAnsweredRequiredQuestions = 0

        const {
            control: { _fields }
        } = methods

        const latestValues = methods.getValues()

        for (const id in _fields) {
            const field = _fields[id]._f
            if (field.mount && field?.required) {
                const questionAnswered = guessQuestionAnsweredByAnswerType(
                    latestValues[id]
                )

                if (checkQuestionIsForTab(tabIndex, id)) {
                    tabTotalRequiredQuestions++

                    if (questionAnswered) {
                        tabAnsweredRequiredQuestions++
                    }
                }

                if (!questionAnswered) {
                    questionnaireCompleted = false
                }
            }
        }

        return {
            questionnaireCompleted,
            tabTotalRequiredQuestions,
            tabAnsweredRequiredQuestions
        }
    }

    const handleQuestionAnswerStatusChanged = (
        newAnswer,
        questionName,
        question,
        tabIndex
    ) => {
        if (isMounted && onChange) {
            const {
                questionnaireCompleted,
                tabTotalRequiredQuestions,
                tabAnsweredRequiredQuestions
            } = calculateQuestionnaireStatus(tabIndex)

            setTabsAnsweredQuestions((currentState) => {
                const newState = [...currentState]
                newState[tabIndex] = {
                    required: tabTotalRequiredQuestions,
                    answered: tabAnsweredRequiredQuestions
                }
                return newState
            })

            onChange({
                changedItem: {
                    question: questionName,
                    answer: renderQuestionAnswerByType(
                        newAnswer,
                        question,
                        latestValues
                    )
                },
                questionnaireCompleted: questionnaireCompleted
            })
        }
    }

    React.useEffect(() => {
        if(methods.control._state.mount){
            const tabsQuestionsAnswers = []

            questions.forEach((_tabQuestions, tabIndex) => {
                const {
                    tabTotalRequiredQuestions,
                    tabAnsweredRequiredQuestions
                } = calculateQuestionnaireStatus(tabIndex)

                tabsQuestionsAnswers[tabIndex] = {
                    required: tabTotalRequiredQuestions,
                    answered: tabAnsweredRequiredQuestions
                }
            })

            setTabsAnsweredQuestions(tabsQuestionsAnswers)

            setIsMounted(true)
        }
    }, [methods.control._state.mount])

    // React.useEffect(() => {
    //     const { unsubscribe } = methods.watch((value) => {
    //       console.log(value)
    //     })
    //     return () => unsubscribe()
    // }, [methods.watch])

    if (!isMounted) return null

    const { tabSettings, buttonsSettings } = settings || {}

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <Accordion defaultActiveKey={activeTabIndex}>
                    {questions.map((tab, tabIndex) => {
                        return (
                            <Accordion.Item
                                key={tabIndex}
                                onClick={() => setActiveTabIndex(0)}
                                className={
                                    activeTabIndex === tabIndex ? 'active' : ''
                                }
                                eventKey={tabIndex}
                            >
                                <Accordion.Header>
                                    <div className='section-header'>
                                        <span>
                                            {`${
                                                tabSettings?.showNumber &&
                                                tabIndex + 1 + '.'
                                            } ${t(tab.title)}`}
                                        </span>
                                        {tabSettings?.showProgressBar && (
                                            <div
                                                className='completed-percent'
                                                style={{
                                                    width: `${tabsAnsweredQuestions[tabIndex]?.answered / tabsAnsweredQuestions[tabIndex]?.required * 100}%`
                                                }}
                                            />
                                        )}
                                    </div>
                                </Accordion.Header>

                                <Accordion.Body>
                                    {(tab?.questions || []).map(
                                        (question, index) => {
                                            return (
                                                <ReactStrapQuestion
                                                    key={index}
                                                    question={question}
                                                    settings={settings}
                                                    // onAnswerStatusChanged={(
                                                    //     questionName,
                                                    //     newAnswer,
                                                    //     question
                                                    // ) => {
                                                    //     handleQuestionAnswerStatusChanged(
                                                    //         newAnswer,
                                                    //         questionName,
                                                    //         question,
                                                    //         tabIndex
                                                    //     )
                                                    // }}
                                                />
                                            )
                                        }
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>

                <Row>
                    <Col
                        sm='12'
                        className='d-flex justify-content-between mt-2 mb-2'
                    >
                        {!!buttonsSettings.back?.display && (
                            <Button
                                onClick={() => {
                                    if (buttonsSettings.back?.onClick) {
                                        buttonsSettings.back.onClick()
                                    }
                                }}
                                disabled={!buttonsSettings.back?.enable}
                                color='btn btn-secondary'
                                className={`${buttonsSettings?.back?.customClass}`}
                            >
                                {t(buttonsSettings?.back?.title || 'Back')}
                            </Button>
                        )}

                        {!!buttonsSettings.submit?.display && (
                            <Button
                                disabled={!buttonsSettings.submit?.enable}
                                type='submit'
                                color='primary'
                                className={`${buttonsSettings?.submit?.customClass}`}
                            >
                                {t(buttonsSettings?.submit?.title || 'Submit')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </form>
        </FormProvider>
    )
})