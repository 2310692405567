import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import ReactDragListView from 'react-drag-listview'
import { PageContext } from '../../Context/PageContext'
import { Question } from '../Question'
import { QuestionTypes } from '../../constants'

const Panel = withNamespaces()(({ t, lng, onChangeImageBtnClicked, ...elseProps }) => {
    const {
        elements,
        deleteElement,
        setElementField,
        setElementStateField,
        addElement,
        onDragEnd,
        elementsRefs
    } = React.useContext(PageContext)

    const defaultNewQuestionType = QuestionTypes.find((t) => t.isDefault)

    const [newQuestionType, setNewQuestionType] = React.useState(
        defaultNewQuestionType
    )

    const saveTitle = (value) => {
        setElementField(elseProps.id, 'title', value)
        setElementStateField(elseProps.id, 'editTitle', false)
    }

    const childElements = (elseProps.elements || []).map((treeItem) => {
        return { ...elements.find((element) => element.id === treeItem.id) }
    })

    return (
        <div
            className='smartintegrity__questionnaire__designer-question-item question page-question panel'
            ref={(ref) =>
                elementsRefs?.current &&
                elementsRefs.current.has(elseProps.id) &&
                elementsRefs.current.set(elseProps.id, ref)
            }
        >
            <span className='smartintegrity__questionnaire__designer-drag-btn question page-question'>
                <i className='ri-drag-move-2-fill' />
            </span>

            <div className='smartintegrity__questionnaire__designer-question-actions'>
                <button
                    className='text-danger'
                    onClick={() => {
                        deleteElement(elseProps.id)
                    }}
                >
                    <i className='ri-delete-bin-line me-2' />
                    {t('Delete')}
                </button>
                <button
                    className='text-dark'
                    onClick={() => {
                        setElementStateField(
                            elseProps.id,
                            'collapsed',
                            !elseProps.state?.collapsed
                        )
                    }}
                >
                    {elseProps.state?.collapsed ? (
                        <React.Fragment>
                            <i className='ri-arrow-up-s-line' />
                            {t('Close')}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <i className='ri-arrow-down-s-line' />
                            {t('Open')}
                        </React.Fragment>
                    )}
                </button>
            </div>

            <div className='smartintegrity__questionnaire__designer-question-title'>
                {elseProps?.state?.editTitle ? (
                    <Input
                        placeholder={t('Panel Title')}
                        className='w-auto'
                        type='text'
                        defaultValue={elseProps.title}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                saveTitle(e.target.value)
                            }
                        }}
                        onBlur={(e) => {
                            saveTitle(e.target.value)
                        }}
                    />
                ) : (
                    <h3
                        onClick={() => {
                            setElementStateField(
                                elseProps.id,
                                'editTitle',
                                true
                            )
                        }}
                    >
                        {elseProps.title}
                    </h3>
                )}
            </div>

            <div
                className='smartintegrity__questionnaire__designer-question-body'
                style={
                    elseProps.state?.collapsed
                        ? {
                              height: 'auto'
                          }
                        : {
                              height: '0px'
                          }
                }
            >
                <ReactDragListView
                    nodeSelector='.smartintegrity__questionnaire__designer-question-item.question.panel-item'
                    handleSelector='.smartintegrity__questionnaire__designer-drag-btn.question.panel-item'
                    onDragEnd={(fromIndex, toIndex) => {
                        onDragEnd(fromIndex, toIndex, elseProps.id)
                    }}
                    lineClassName='smartintegrity__questionnaire__designer-question-item-placeholder'
                >
                    {childElements.map((childElement, elementIndex) => {
                        return (
                            <Question
                                className='panel-item'
                                key={elementIndex}
                                {...childElement}
                            />
                        )
                    })}
                </ReactDragListView>
            </div>

            {lng === 'en' && (
                <div className='smartintegrity__questionnaire__designer-add-new-question-btn-container'>
                    <button
                        onClick={() =>
                            addElement('question', {
                                title: t('Question Title'),
                                placeholder: newQuestionType.placeholder,
                                questionType: newQuestionType.value,
                                parentElementID: elseProps.id
                            })
                        }
                        className='smartintegrity__questionnaire__designer-add-new-question-btn'
                    >
                        <i className='ri-add-fill text-success' />
                        {t('Add Question')}
                    </button>
                    <UncontrolledDropdown>
                        <DropdownToggle caret color='gray'>
                            {newQuestionType?.title}
                        </DropdownToggle>
                        <DropdownMenu>
                            {QuestionTypes.map((questionType, typeIndex) => {
                                return (
                                    <DropdownItem
                                        onClick={() => {
                                            setNewQuestionType(questionType)
                                        }}
                                        key={typeIndex}
                                    >
                                        {questionType.title}
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )}
        </div>
    )
})

export { Panel }
