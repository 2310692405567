import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { FormGroup, Label, Input, FormFeedback, Row, Col } from 'reactstrap'
import { Controller } from 'react-hook-form'

const RadioBox = withNamespaces()(({
    name,
    label,
    validation,
    options,
    onAnswerStatusChanged,
    subQuestionRenerer,
    t,
    ...rest
}) => {
    const {
        control,
        formState: { errors, defaultValues }
    } = rest
    const [currentSelectedOption, setCurrentSelectedOption] =
        React.useState(null)

    const handleOptionChangeEvent = (e, option) => {
        onAnswerStatusChanged &&
            onAnswerStatusChanged(name, rest.getValues(name))
        setCurrentSelectedOption(option)
    }

    const renderOptionSubItem = (control, errors) => {
        if (!['date', 'text'].includes(currentSelectedOption.type)) return null

        const subInputName = `${name}-${currentSelectedOption.type}`
        control.register(subInputName, {
            // required: !!validation?.required
            required: true
        })
        return (
            <div>
                <Controller
                    name={subInputName}
                    control={control}
                    render={({ field }) => {
                        if (currentSelectedOption.type === 'date') {
                            return (
                                <Input
                                    {...field}
                                    type='date'
                                    defaultValue={defaultValues[subInputName]}
                                />
                            )
                        }
                        return <Input {...field} type='text' />
                    }}
                />
                {errors && errors[subInputName] && (
                    <FormFeedback className='d-block' valid={false}>
                        {t(`This field cannot be blank`)}
                    </FormFeedback>
                )}
            </div>
        )
    }

    const renderChildren = () => {
        if (
            currentSelectedOption &&
            currentSelectedOption?.children &&
            currentSelectedOption.children.length > 0
        ) {
            return (
                <div className='children-questions-container'>
                    {currentSelectedOption.children.map(
                        (subQuestion, subQuestionIndex) => {
                            return (
                                <div
                                    className='children-question'
                                    key={subQuestionIndex}
                                >
                                    {subQuestionRenerer({
                                        ...subQuestion,
                                        parentQuestion: name,
                                        options:
                                            subQuestion.type === 'radiobox'
                                                ? [
                                                      {
                                                          id: 1,
                                                          title: 'Yes'
                                                      },
                                                      {
                                                          id: 0,
                                                          title: 'No'
                                                      }
                                                  ]
                                                : undefined
                                    })}
                                </div>
                            )
                        }
                    )}
                </div>
            )
        }
    }

    React.useEffect(() => {
        if (defaultValues[name]) {
            const optionToSelectAsDefault = options.find((option) => {
                return (
                    (option?.id || '').toString() ===
                    (defaultValues[name] || '').toString()
                )
            })

            if (optionToSelectAsDefault) {
                setCurrentSelectedOption(optionToSelectAsDefault)
            }
        }
    }, [])

    control.register(name, {
        required: !!validation?.required
    })

    return (
        <FormGroup>
            {label && <Label for={name}>{t(label)}</Label>}
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <Row>
                            <Col sm='12'>
                                <Row>
                                    {options.map((option, index) => {
                                        return (
                                            <Col
                                                key={index}
                                                sm='12'
                                                md='4'
                                                lg='3'
                                            >
                                                <Label>
                                                    <Input
                                                        {...field}
                                                        defaultChecked={
                                                            (
                                                                defaultValues[
                                                                    name
                                                                ] || ''
                                                            ).toString() ===
                                                            (
                                                                option?.id || ''
                                                            ).toString()
                                                        }
                                                        type='radio'
                                                        value={option?.id}
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                            handleOptionChangeEvent(
                                                                e,
                                                                option
                                                            )
                                                        }}
                                                    />{' '}
                                                    {t(option?.title)}
                                                </Label>
                                                {currentSelectedOption &&
                                                    currentSelectedOption.id ===
                                                        option.id && (
                                                        <div className='custom-radio-option-type'>
                                                            {renderOptionSubItem(
                                                                control,
                                                                errors
                                                            )}
                                                        </div>
                                                    )}
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    )
                }}
            />
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
            {renderChildren()}
        </FormGroup>
    )
})

export { RadioBox }
