import { createContext } from 'react'

export const PageContext = createContext({
    elements: [],
    elementsTree: [],
    elementsRefs: null,
    currentEditingPage: null,

    setCurrentEditingPage: () => {},

    setElements: () => {},
    addElement: () => {},
    deleteElement: () => {},
    setElementField: () => {},
    setElementStateField: () => {},

    addQuestionOption: () => {},
    deleteQuestionOption: () => {},
    setQuestionOptionField: () => {},
    setQuestionOptionStateField: () => {},

    addQuestionOptionChild: () => {},
    setQuestionOptionChildField: () => {},
    deleteQuestionOptionChild: () => {},
    setQuestionOptionChildStateField: () => {},

    addSubQuestionOption: () => {},
    setSubQustionOptionField: () => {},
    setSubQustionOptionStateField: () => {},
    deleteSubQuestionOption: () => {},

    onDragEnd: () => {},
    reorder: () => {}
})
